import { IAm, IAmEducator } from '@shared/types';

const DEFAULT_DESTINATION = `${process.env.NEXT_PUBLIC_MARKETPLACE_URL}`;
const STUDENT_DEFAULT_DESTINATION = `${process.env.NEXT_PUBLIC_MARKETPLACE_URL}/profile`;
const ADMIN_DEFAULT_DESTINATION = `${process.env.NEXT_PUBLIC_ADMIN_URL}`;

const PERSISTENCE_KEY = 'authRedirect';

/**
 * Users intended destination
 */
const INTENT_KEY = 'redirect';

const ALLOWED_REDIRECT_DOMAINS = [
    process.env.NEXT_PUBLIC_ALLOWED_REDIRECT_DOMAIN || process.env.NEXT_PUBLIC_DOMAIN_NAME!,
];

const redirectToProfile = (educators: Array<IAmEducator>): string => {
    if (educators.length > 0) {
        return ADMIN_DEFAULT_DESTINATION;
    } else {
        return STUDENT_DEFAULT_DESTINATION;
    }
};

const Redirect = {
    init: (redirectUrl?: URL) => {
        if (redirectUrl) {
            return persistUrl(redirectUrl);
        }
        return persistIntentFromUrl();
    },

    go: (iam?: IAm) => {
        const redirectDestination: string =
            iam && iam.authenticated ? redirectToProfile(iam.educators) : DEFAULT_DESTINATION;
        const destination = takeRedirectUrl(redirectDestination);
        window.location.href = destination;
    },
};

const takeRedirectUrl = (defaultDestination: string): string => {
    const destination = getRedirectUrl(defaultDestination);

    dropRedirectFromStorage();

    return destination;
};

const getRedirectUrl = (defaultDestination: string): string => {
    const persistedIntent = getRedirectFromStorage();

    if (persistedIntent) {
        return persistedIntent;
    }

    return defaultDestination;
};

const dropRedirectFromStorage = (): void => {
    window.localStorage.removeItem(PERSISTENCE_KEY);
};

const getRedirectFromStorage = (): string | undefined => {
    const value = window.localStorage.getItem(PERSISTENCE_KEY);

    if (value) {
        return value;
    }

    return undefined;
};

const isValidRedirect = (url: URL) => {
    return ALLOWED_REDIRECT_DOMAINS.some((allowedDomain) => {
        return url.hostname.endsWith(allowedDomain);
    });
};

const persistIntentFromUrl = () => {
    dropRedirectFromStorage();

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const redirect = urlParams.get(INTENT_KEY);

    if (redirect && redirect !== `${DEFAULT_DESTINATION}/`) {
        try {
            const redirectUrl = new URL(decodeURIComponent(redirect), window.location.toString());
            persistUrl(redirectUrl);
        } catch (error) {
            console.error(error);
        }
    }
};

const persistUrl = (redirectUrl: URL) => {
    if (isValidRedirect(redirectUrl)) {
        window.localStorage.setItem(PERSISTENCE_KEY, redirectUrl.toString());
    }
};

export default Redirect;
