import Image from 'next/image';
import Link from 'next/link';
import { FunctionComponent } from 'react';
import styles from './styles.module.scss';
import { PublicEducator } from '@cademy-package/sdk-component-types';

type EducatorLogoProps = {
    src: string;
    alt: string;
};

const EducatorLogo: FunctionComponent<EducatorLogoProps> = ({ src, alt }) => {
    return <Image src={src} alt={alt} height={100} width={100} className={styles.logo} />;
};

const CademyLogo = () => {
    return (
        <Image
            src={`${process.env.NEXT_PUBLIC_ASSETS_URL}/logos/logo-resized.svg`}
            alt="Cademy"
            width={184}
            height={30}
        />
    );
};

export type LogoProps = {
    educator: PublicEducator;
};

const Logo: FunctionComponent<LogoProps> = ({ educator }) => {
    const landingPageUrl = getLandingPageUrl(educator);

    if (educator.logo_url) {
        return (
            <Link href={landingPageUrl}>
                <EducatorLogo src={educator.logo_url} alt={educator.name} />
            </Link>
        );
    }

    return (
        <Link href={landingPageUrl}>
            <CademyLogo />
        </Link>
    );
};

const getLandingPageUrl = (educator: PublicEducator) => {
    if (process.env.NODE_ENV !== 'production') {
        return `${process.env.NEXT_PUBLIC_MARKETPLACE_URL}/${educator.slug}`;
    }

    return `https://${educator.slug}.${process.env.NEXT_PUBLIC_DOMAIN_NAME}`;
};

export default Logo;
