import { FunctionComponent, PropsWithChildren } from 'react';
import BaseLayout, { BaseLayoutProps } from '../../../../layouts/BaseLayout';
import BodyWithStyle from '../../../../components/BodyWithStyle';
import styles from './styles.module.scss';
import Logo from './Logo';
import { PublicEducator } from '@cademy-package/sdk-component-types';

type EducatorLayoutProps = BaseLayoutProps & {
    educator: PublicEducator;
};

const EducatorLayout: FunctionComponent<EducatorLayoutProps> = ({
    title,
    description,
    educator,
    children,
}: EducatorLayoutProps) => {
    return (
        <BaseLayout title={title} description={description}>
            <BodyWithStyle />

            <header className={styles.header}>
                <Logo educator={educator} />
            </header>

            {children}
        </BaseLayout>
    );
};

export default EducatorLayout;
