import { FunctionComponent, PropsWithChildren, useCallback, useEffect } from 'react';
import MinimalLayout from '../../layouts/MinimalLayout';
import styles from './styles.module.scss';
import Redirect from '../../pages_components/login/Services/Redirect';
import EducatorLayout from '../../pages_components/login/Layouts/EducatorLayout';
import { GetServerSideProps } from 'next';
import { IAm } from '@shared/types';
import { PublicEducator } from '@cademy-package/sdk-component-types';
import GetEducator from '../../pages_components/login/Queries/GetEducator';
import { ParsedUrlQuery } from 'node:querystring';
import { Login } from '../../components/Login';
import { getIAm } from '../../services/IAm/getIAm';
import { EducatorPlanContext } from '../../contexts/PlanContext';

export type LoginProps = {
    educator: PublicEducator | null;
    message: string | null;
};

const LoginPage: FunctionComponent<LoginProps> = ({ educator, message }) => {
    useEffect(() => {
        Redirect.init();
    }, []);

    const onAuthenticated = useCallback((iam: IAm) => {
        Redirect.go(iam);
    }, []);

    return (
        <LoginLayout educator={educator || undefined}>
            <Login message={message || undefined} onAuthenticated={onAuthenticated} />
        </LoginLayout>
    );
};

const PAGE_TITLE = 'Log in or Sign Up to Cademy';
const PAGE_DESCRIPTION = 'Log in or sign up to Cademy and browse hundreds of courses near you.';

type LoginLayoutProps = PropsWithChildren<{
    educator?: PublicEducator;
}>;

const LoginLayout: FunctionComponent<LoginLayoutProps> = ({ educator, children }) => {
    if (educator) {
        return (
            <EducatorPlanContext
                whitelabellingEnabled={educator.whitelabellingEnabled}
                branding={educator.branding}
            >
                <EducatorLayout
                    title={PAGE_TITLE}
                    description={PAGE_DESCRIPTION}
                    educator={educator}
                >
                    <div className={styles.container}>
                        <div className={styles.contentContainer}>{children}</div>
                    </div>
                </EducatorLayout>
            </EducatorPlanContext>
        );
    }

    return (
        <MinimalLayout title={PAGE_TITLE} description={PAGE_DESCRIPTION}>
            <div className={styles.container}>
                <div className={styles.contentContainer}>{children}</div>
            </div>
        </MinimalLayout>
    );
};

const getQueryValue = (query: ParsedUrlQuery, key: string): string | null => {
    if (!query) {
        return null;
    }
    const value = query[key];
    if (!value) {
        return null;
    }
    if (Array.isArray(value)) {
        return value[0];
    }
    return value;
};

export const getServerSideProps: GetServerSideProps<LoginProps> = async (context) => {
    const educatorId = getQueryValue(context.query, 'educator');
    const message = getQueryValue(context.query, 'message');

    try {
        const educator = educatorId ? await GetEducator(educatorId) : null;
        return {
            props: {
                initialIAm: await getIAm(context.req.cookies),
                educator: educator,
                message: message || null,
            },
        };
    } catch (error) {
        // fallback to normal login layout
        return {
            props: {
                initialIAm: await getIAm(context.req.cookies),
                educator: null,
                message: message || null,
            },
        };
    }
};

export default LoginPage;
